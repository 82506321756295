// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-autohaeuser-mdx": () => import("./../../../../docs/Autohaeuser.mdx" /* webpackChunkName: "component---docs-autohaeuser-mdx" */),
  "component---docs-banken-mdx": () => import("./../../../../docs/Banken.mdx" /* webpackChunkName: "component---docs-banken-mdx" */),
  "component---docs-datenschutz-mdx": () => import("./../../../../docs/Datenschutz.mdx" /* webpackChunkName: "component---docs-datenschutz-mdx" */),
  "component---docs-impressum-mdx": () => import("./../../../../docs/Impressum.mdx" /* webpackChunkName: "component---docs-impressum-mdx" */),
  "component---docs-installation-mdx": () => import("./../../../../docs/Installation.mdx" /* webpackChunkName: "component---docs-installation-mdx" */),
  "component---docs-kontakt-mdx": () => import("./../../../../docs/Kontakt.mdx" /* webpackChunkName: "component---docs-kontakt-mdx" */),
  "component---docs-krankenhaeuser-mdx": () => import("./../../../../docs/Krankenhaeuser.mdx" /* webpackChunkName: "component---docs-krankenhaeuser-mdx" */),
  "component---docs-oeffentl-auftritt-mdx": () => import("./../../../../docs/OeffentlAuftritt.mdx" /* webpackChunkName: "component---docs-oeffentl-auftritt-mdx" */),
  "component---docs-pflegeeinrichtungen-mdx": () => import("./../../../../docs/Pflegeeinrichtungen.mdx" /* webpackChunkName: "component---docs-pflegeeinrichtungen-mdx" */),
  "component---docs-private-mdx": () => import("./../../../../docs/Private.mdx" /* webpackChunkName: "component---docs-private-mdx" */),
  "component---docs-referenzen-mdx": () => import("./../../../../docs/Referenzen.mdx" /* webpackChunkName: "component---docs-referenzen-mdx" */),
  "component---docs-service-mdx": () => import("./../../../../docs/Service.mdx" /* webpackChunkName: "component---docs-service-mdx" */),
  "component---docs-startseite-mdx": () => import("./../../../../docs/Startseite.mdx" /* webpackChunkName: "component---docs-startseite-mdx" */),
  "component---docs-stellen-mdx": () => import("./../../../../docs/Stellen.mdx" /* webpackChunkName: "component---docs-stellen-mdx" */),
  "component---docs-unternehmen-mdx": () => import("./../../../../docs/Unternehmen.mdx" /* webpackChunkName: "component---docs-unternehmen-mdx" */),
  "component---docs-verbaende-mdx": () => import("./../../../../docs/Verbaende.mdx" /* webpackChunkName: "component---docs-verbaende-mdx" */),
  "component---docs-vermietung-mdx": () => import("./../../../../docs/Vermietung.mdx" /* webpackChunkName: "component---docs-vermietung-mdx" */),
  "component---docs-wartung-mdx": () => import("./../../../../docs/Wartung.mdx" /* webpackChunkName: "component---docs-wartung-mdx" */),
  "component---docs-wiruberuns-mdx": () => import("./../../../../docs/Wiruberuns.mdx" /* webpackChunkName: "component---docs-wiruberuns-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

