module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"mode":"dark"},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[{"name":"Zusammenarbeit"},{"name":"Leistungen"},{"name":"Informationen"}],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"dest":"public","d":"public","root":"/opt/build/repo/.docz","base":"/","source":"./","gatsby-root":null,"files":"docs/**/*.{md,markdown,mdx}","public":"/public","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Docz & Netlify CMS","description":"Haustechnik Heizung Klima Lüftung Sachsen in Sachsen","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/opt/build/repo","templates":"/opt/build/repo/node_modules/docz-core/dist/templates","docz":"/opt/build/repo/.docz","cache":"/opt/build/repo/.docz/.cache","app":"/opt/build/repo/.docz/app","appPackageJson":"/opt/build/repo/package.json","appTsConfig":"/opt/build/repo/tsconfig.json","gatsbyConfig":"/opt/build/repo/gatsby-config.js","gatsbyBrowser":"/opt/build/repo/gatsby-browser.js","gatsbyNode":"/opt/build/repo/gatsby-node.js","gatsbySSR":"/opt/build/repo/gatsby-ssr.js","importsJs":"/opt/build/repo/.docz/app/imports.js","rootJs":"/opt/build/repo/.docz/app/root.jsx","indexJs":"/opt/build/repo/.docz/app/index.jsx","indexHtml":"/opt/build/repo/.docz/app/index.html","db":"/opt/build/repo/.docz/app/db.json"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"../src/cms/cms.js"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0RMN59H4VC"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":350},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
