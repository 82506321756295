import React from "react";
import start from "./startseite.svg";

export const Start = () => {
	return (
		<div style={{ width: "40%", margin: "auto" }}>
			<img src={start} alt='Strichmännchen Berger Haustechnik' />
		</div>
	);
};
